<script>
import { alphaNumDash, domain } from '@shared/config/vuelidate';
import { genericForms } from '@shared/mixins/forms';
import APIStore from '@app/services/API/Store';
import ConfirmationLanguageChangeModal from '@app/components/ConfirmationLanguageChangeModal.vue';

export default {
  mixins: [genericForms],
  data() {
    return {
      hasCustomEmails: false,
      isLoading: false,
      store: {
        name: '',
        subdomain: '',
        custom_domain: '',
        feature_options: {
          language: {
            language_option: '',
          },
        },
      },
      initialLanguage: '', // Add this line
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    hasPercentPlan() {
      return this.$store.getters['auth/hasPercentPlan'];
    },
    isNotPercentPlan() {
      return this.$store.getters['auth/isNotPercentPlan'];
    },
    subdomainUrl() {
      return this.$store.getters['auth/subdomainUrl'];
    },
    customDomainUrl() {
      return this.$store.getters['auth/customDomainUrl'];
    },
    hasCustomDomainActivated() {
      return this.$store.getters['auth/hasCustomDomainActivated'];
    },
    hasLanguageChanged() {
      return this.store.feature_options.language.language_option !== this.initialLanguage;
    },
  },
  validations: {
    store: {
      subdomain: {
        alphaNumDash,
      },
      custom_domain: {
        domain,
      },
    },
  },
  created() {
    this.store.name = this.authStore.name;
    this.store.subdomain = this.authStore.subdomain;
    if (this.isNotPercentPlan) {
      this.store.custom_domain = this.authStore.custom_domain;
    }
    this.store.feature_options = this.authStore.feature_options;
    this.initialLanguage = this.authStore.feature_options.language.language_option; // Add this line

    APIStore.getEmailTemplates(this.authStore.uuid)
      .then((data) => {
        this.hasCustomEmails = data.data.filter((template) => template.subject || template.text).length > 0;
      });
  },
  methods: {
    action() {
      const store = { ...this.store };
      this.hasPercentPlan && (delete store.custom_domain);

      return this.$store.dispatch('auth/updateStore', store)
        .then(() => {
          // Update initialLanguage after successful submission
          this.initialLanguage = this.store.feature_options.language.language_option;
        });
    },
    confirmLanguageChange() {
      if (this.hasLanguageChanged && this.hasCustomEmails) {
        return this.$buefy.modal.open({
          parent: this,
          component: ConfirmationLanguageChangeModal,
          props: {
            handleSubmit: this.handle,
          },
        });
        // return this.$buefy.dialog.confirm({
        //   title: 'Confirmation',
        //   message: 'Voulez-vous vraiment changer la langue de votre espace ? Certains de vos e-mails marketing ont été personnalisés et devront être traduits pour correspondre à la nouvelle langue.',
        //   confirmText: 'Oui',
        //   cancelText: 'Non',
        //   type: 'is-warning',
        //   focusOn: 'cancel',
        //   onConfirm: () => this.handle(),
        // });
      }
      return this.handle();
    },
  },
};
</script>

<template>
  <div class="columns is-multiline">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Informations obligatoires
      </h2>
      <p>
        Renseignez les informations obligatoires
        pour que nous puissions configurer votre espace.
      </p>
      <p>
        Attention ! Si vous modifiez l'URL de votre espace,
        n'oubliez d'en informer vos apprenants.
      </p>
    </div>

    <div class="column is-8">
      <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <div class="columns is-multiline">
          <div class="column is-12">
            <b-field label="Nom de l'espace" message="Ce nom sera affiché aux apprenants">
              <b-input
                v-model="store.name"
                maxlength="128"
                :has-counter="false"
                :use-html5-validation="false"
                required
              />
            </b-field>
          </div>
          <div class="column is-12">
            <div class="field">
              <label class="label">
                URL de votre espace
              </label>
            </div>
            <b-field :addons="false" v-bind="$getErrorProps($v.store.subdomain, ['alphaNumDash'])">
              <div class="field has-addons">
                <b-input
                  v-model="store.subdomain"
                  maxlength="128"
                  :has-counter="false"
                  :use-html5-validation="false"
                  required
                  expanded
                />
                <p class="control">
                  <span class="button is-static">.teachizyplus.fr</span>
                </p>
                <p class="control">
                  <a
                    class="button is-primary"
                    :href="subdomainUrl"
                    target="_blank"
                  >
                    <b-icon icon="link" />
                  </a>
                </p>
              </div>
              <p class="help">
                C'est le lien d'accès à votre site Teachizy.
                <a class="tdecoration-underline" :href="subdomainUrl" target="_blank">
                  Cliquez ici pour y accéder
                </a>
              </p>
            </b-field>
          </div>
        </div>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>

    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Domaine personnalisé <AppFeatureBadge class="ml-3" position="is-right" />
      </h2>
      <p>
        Connectez un sous-domaine qui vous appartient
        en quelques étapes simples.
      </p>
    </div>

    <div class="column is-8">
      <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <h3 class="title is-5">
          Vous disposez déjà d'un nom de domaine ?
        </h3>
        <div class="notification content">
          <p>
            Vous pouvez faire apparaître votre espace Teachizy+ directement
            sur votre sous-domaine (comme <strong>formations.monsite.fr</strong>).
          </p>

          <template v-if="isNotPercentPlan">
            <p>
              Pour cela rien de plus simple !
              <a href="https://youtu.be/mhPkgX3BIdc">Cliquez ici</a>
              pour regarder notre tutoriel vidéo sur le sujet.
            </p>
            <RenderlessToggle #default="{ isOpen, toggle }" :open="false">
              <div>
                <p>
                  Autrement, vous pouvez aussi
                  <a class="tdecoration-underline" href="#" @click.prevent="toggle">cliquer là</a> pour voir les étapes.
                </p>
                <ol v-show="isOpen">
                  <li>
                    Créez ou achetez le nom de domaine désiré si ce n'est pas encore fait
                  </li>
                  <li>
                    Allez dans la <strong>zone DNS</strong> ou <strong>zone editor</strong>
                    sur votre hébergeur ou registrar (ovh, gandi, ...)
                  </li>
                  <li>
                    Pour le sous-domaine désiré, créez un
                    <strong>champ CNAME</strong> avec pour valeur
                    <strong>domains.teachizyplus.fr</strong>.
                  </li>
                  <li>
                    Renseignez le sous-domaine dans notre champ texte ci-dessous
                  </li>
                  <li>
                    Contactez-nous par email (allo@teachizyplus.fr) ou par tchat avec une
                    capture d'écran ainsi que le sous-domaine renseigné de votre configuration
                    pour validation.
                  </li>
                </ol>
              </div>
            </RenderlessToggle>
            <p>
              L'activation du sous-domaine personnalisé peut prendre 24h à 48h en fonction
              de votre hébergeur ou registrar.
            </p>
          </template>
        </div>
        <GoProWrapper>
          <b-field :addons="false" v-bind="$getErrorProps($v.store.custom_domain, ['domain'])">
            <div class="field" :class="{'has-addons': authStore.custom_domain}">
              <b-input
                v-model="store.custom_domain"
                maxlength="128"
                :has-counter="false"
                :use-html5-validation="false"
                expanded
              />
              <p v-if="hasCustomDomainActivated" class="control">
                <a
                  class="button is-primary"
                  :href="customDomainUrl"
                  target="_blank"
                >
                  <b-icon icon="link" />
                </a>
              </p>
            </div>
            <p class="help">
              C'est le lien d'accès à votre site Teachizy+.
              <a
                v-show="hasCustomDomainActivated"
                class="tdecoration-underline"
                :href="customDomainUrl" target="_blank">
                Cliquez ici pour y accéder
              </a>
              <br>
              Votre nom de domaine doit avoir un <strong>champ CNAME</strong>
              avec la valeur <strong>domains.teachizyplus.fr</strong>.
            </p>
          </b-field>

          <b-field class="has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </b-field>
        </GoProWrapper>
      </form>
    </div>

    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Langue
      </h2>
      <p>
        Définissez la langue de votre espace apprenant.
        La langue par défaut est le français.
      </p>
    </div>

    <div class="column is-8">
      <div class="box">
        <GoProWrapper planLock="EXPERT" :hasSep="false">
          <form @submit.prevent="dataIsValid(isLoading) && confirmLanguageChange()">
            <div class="columns is-multiline">
              <div class="column is-12">
                <b-field label="Choix de la langue" message="Sélectionnez la langue de l'interface pour vos apprenants. Vos contenus (leçons, quiz, etc) ne sont pas impactés.">
                  <b-select v-model="store.feature_options.language.language_option" expanded>
                    <option value="fr">
                      Français
                    </option>
                    <option value="en">
                      Anglais
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>

            <b-field class="has-text-right">
              <b-button type="is-primary" native-type="submit" :loading="isLoading">
                Mettre à jour
              </b-button>
            </b-field>
          </form>
        </GoProWrapper>
      </div>
    </div>
  </div>
</template>
