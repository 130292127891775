<script>
import { inArray } from '@shared/config/vuelidate';
import { storeForms } from '@app/mixins/forms';

export default {
  mixins: [storeForms],
  data() {
    return {
      store: {
        password: '',
        status: '',
      },
    };
  },
  validations: {
    store: {
      status: {
        inArray: inArray(['ACTIVE', 'PAUSED']),
      },
    },
  },
  methods: {
    confirm() {
      this.$buefy.dialog.prompt({
        message: 'Veuillez saisir votre mot de passe pour confirmer',
        type: 'is-danger',
        inputAttrs: { type: 'password' },
        onCancel: () => {
          this.store.status = this.authStore.status;
        },
        onConfirm: (value) => {
          this.store.password = value;
          this.handle(null, (error) => {
            this.store.status = this.authStore.status;
            this.$errorHandlers.axios(error);
          });
        },
      });
    },
  },
  created() {
    this.store.status = this.authStore.status;
  },
};
</script>

<template>
  <div class="columns">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Suspendre les inscriptions
      </h2>
      <p>
        Besoin d'arrêter temporairement vos formations ?
        Vous pouvez désactiver les inscriptions à vos formations, pour la durée de votre choix.
      </p>
    </div>

    <div class="column">
      <div class="box content">
        <p class="has-text-success has-text-weight-bold">
          Les apprenants auront toujours accès aux formations déjà acquises.
        </p>
        <b-field v-bind="$getErrorProps($v.store.status, ['inArray'])">
          <b-switch
            v-model="store.status"
            true-value="PAUSED"
            false-value="ACTIVE"
            @input="dataIsValid(isLoading) && confirm()"
          >
            <span class="is-flex is-align-items-center">
              <span v-if="isLoading" class="loader is-size-5 mr-5" />
              Suspendre les inscriptions
            </span>
          </b-switch>
        </b-field>
      </div>
    </div>
  </div>
</template>
